// const lenisSettings = {
// 	duration: 1.2,
// 	easing: ( t ) => ( t === 1 ? 1 : 1 - Math.pow( 2, -10 * t ) ),
// 	orientation: 'vertical',
// 	gestureOrientation: 'vertical',
// 	smoothWheel: true,
// 	smoothTouch: false,
// 	wheelMultiplier: 1.2,
// 	touchMultiplier: 0.2,
// 	inifinite: false
// };
// const lenis = new Lenis( lenisSettings );
// lenis.on( 'scroll', ( e ) => {
//
// });
// function raf( time ) {
// 	lenis.raf( time );
// 	requestAnimationFrame( raf );
// }
// requestAnimationFrame( raf );
//
// function toggleScroll( isScroll = true ) {
// 	if ( isScroll ) {
// 		lenis.start();
// 	} else {
// 		lenis.stop();
// 	}
// }

ScrollOut({
	threshold: 0.2,
	once: true
});

ScrollOut({
	targets: '[data-fade]',
	threshold: 0.2,
	once: true
});

ScrollOut({
	targets: '[data-number-countdown]',
	once: true,
	onShown: function(el) {
		setTimeout(function (){
			var text = $(el).find('.project-number').attr('data-num');
			var value = parseFloat(text);
			if (!isNaN(value)) {
				var decimals = (value % 1 === 0) ? 0 : 1;
				jQuery({ Counter: 0 }).animate({ Counter: value }, {
					duration: 2000,
					easing: 'swing',
					step: function () {
						$(el).find('.project-number span').text(this.Counter.toFixed(decimals));
					}
				});
			}
		},500)
	},

});

$( document ).ready( function() {
	/* - - - - - - - - - - - - - -       init inputmask       - - - - - - - - - - - - - - - */
	$( '[type="tel"]' ).inputmask( '+38 (999) 999 99 99' );

	/* - - - - - - - - - - - - - -       input validate       - - - - - - - - - - - - - - - */
	function setInput() {
		$( 'input:not(.btn):not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"])' ).each( function() {
			const $parent = $( this ).closest( '.form-group, .form-search' );
			if ( $( this ).val() !== '' ) {
				$parent.addClass( 'filled' );
			} else {
				$parent.removeClass( 'filled' );
			}
		});
	}
	setInput();
	$( 'input:not(.btn):not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"])' ).on( 'input', function() {
		const $parent = $( this ).closest( '.form-group, .form-search' );
		if ( $( this ).val() !== '' ) {
			$parent.addClass( 'filled' );
		} else {
			$parent.removeClass( 'filled' );
		}
	});
	$( 'input:not(.btn):not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"])' ).on( 'blur', function() {
		$( 'input:not(.btn):not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"])' ).each( function() {
			const $parent = $( this ).closest( '.form-group, .form-search' );
			if ( $( this ).val() === '' && !$( this ).is( ':focus' ) ) {
				$parent.removeClass( 'filled' );
			}
		});
	});
	$( 'textarea' ).on( 'input', function() {
		const $parent = $( this ).closest( '.form-group' );
		if ( $( this ).val() !== '' ) {
			$parent.addClass( 'filled' );
		} else {
			$parent.removeClass( 'filled' );
		}
	});
	$( document ).on( 'click', '.js-field-clear', function() {
		const $parent = $( this ).closest( '.form-group' );
		$parent.find( 'input' ).val( '' );
		setInput();
	});

	/* - - - - - - - - - - - - - -       init fancybox       - - - - - - - - - - - - - - - */
	Fancybox.bind( '[data-fancybox]', {
		autoFocus: false,
		dragToClose: false,
		closeExisting: true,
		tpl: {
			closeButton: '<button data-fancybox-close class="f-button is-close-btn" title="{{CLOSE}}">' +
				'<span class="icon icon-i-close"></span>' +
				'</button>'
		},
		on: {
			init: (e) => {
				console.log(e)
				$( 'body' ).removeClass( 'no-scroll' );
				const $modal = $( e.userSlides[ 0 ].src );
				$modal.find( '.modal-title__value' ).text( e.userSlides[ 0 ].title );
				$modal.find( '.field-title' ).val( e.userSlides[ 0 ].title );
			},
			close: () => {
				$( '.modal form' )[ 0 ].reset();
				$( '.modal .form-group' ).removeClass( 'filled' );
				$( '.modal-title__name' ).text( '' );
			}
		}
	});

	/* - - - - - - - - - - - - - -       init marquee       - - - - - - - - - - - - - - - */
	let marqueeSpeed = 0;
	if (window.matchMedia("(min-width: 1200px)").matches) {
		marqueeSpeed = 12000;
	} else if (window.matchMedia("(max-width: 767px)").matches) {
		marqueeSpeed = 8000;
	} else {
		marqueeSpeed = 10000;
	}
	$( '.js-marquee' ).marquee({
		duration: marqueeSpeed,
		gap: 0,
		delayBeforeStart: 0,
		direction: 'left',
		duplicated: true,
		startVisible: true
	});

	/* - - - - - - - - - - - - - -       fixed header       - - - - - - - - - - - - - - - */
	function fixedHeader() {
		const top = $( window ).scrollTop();
		if ( top > 0 ) {
			$( '.header' ).addClass( 'fixed' );
		} else {
			$( '.header' ).removeClass( 'fixed' );
		}
	}
	fixedHeader();
	$( window ).on( 'scroll', fixedHeader );

	/* - - - - - - - - - - - - - -       rebuild header       - - - - - - - - - - - - - - - */
	function rebuildScrollHeader() {
		const top = $( window ).scrollTop();
		const $headerPhone = $( '.header-phone' ).detach();
		if ( top > 0 ) {
			$( '.header-logo' ).after( $headerPhone );
		} else {
			$( '.header-inner' ).append( $headerPhone );
		}
	}
	function rebuildResizeHeader() {
		const top = $( window ).scrollTop();
		const $headerPhone = $( '.header-phone' ).detach();
		if ( window.matchMedia( '(max-width: 767px)' ).matches ) {
			if ( top > 0 ) {
				$( '.header-logo' ).after( $headerPhone );
			} else {
				$( '.header-inner' ).append( $headerPhone );
			}
		} else {
			$( '.header-inner' ).append( $headerPhone );
		}
	}
	if ( $( '.header-phone' ).length ) {
		rebuildResizeHeader();
		$( window ).on( 'scroll', function() {
			if ( window.matchMedia( '(max-width: 767px)' ).matches ) {
				rebuildScrollHeader();
			}
		});
		let width = $( window ).innerWidth();
		$( window ).on( 'resize', function() {
			if ( $( window ).innerWidth() !== width ) {
				width = $( window ).innerWidth();
				rebuildResizeHeader();
			}
		});
	}

	/* - - - - - - - - - - - - - -       toggle menu       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.js-menu-toggle', function() {
		const $header = $( '.header' );
		if ( !$header.hasClass( 'show-menu' ) ) {
			$header.addClass( 'show-menu' );
			$( 'body' ).addClass( 'no-scroll' );
			// toggleScroll( false );
		} else {
			$header.removeClass( 'show-menu' );
			$( 'body' ).removeClass( 'no-scroll' );
			// toggleScroll( true );
		}
	});

	/* - - - - - - - - - - - - - -       scroll to       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.js-scroll-to', function( e ) {
		e.preventDefault();
		const link = $( this ).attr( 'href' );
		if ( $( 'link' ).length ) {
			$( 'html, body' ).animate({ scrollTop: $( link ).offset().top - $( '.header' ).innerHeight() }, 500 );
			$( '.header' ).removeClass( 'show-menu' );
			$( 'body' ).removeClass( 'no-scroll' );
			// toggleScroll( true );
		}
	});

	/* - - - - - - - - - - - - - -       toggle lang       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.js-lang', function() {
		const $parent = $( this ).closest( '.lang' );
		if ( !$parent.hasClass( 'show' ) ) {
			$parent.addClass( 'show' );
		} else {
			$parent.removeClass( 'show' );
		}
	});
	$( document ).on( 'click', function( e ) {
		if ( $( e.target ).closest( '.lang' ).length ) {
			return;
		}
		$( '.lang' ).removeClass( 'show' );
	});
	$( window ).on( 'resize', function() {
		if ( window.matchMedia( '(min-width: 768px)' ).matches ) {
			$( '.lang' ).removeClass( 'show' );
		}
	});

	/* - - - - - - - - - - - - - -       parallax banner       - - - - - - - - - - - - - - - */
	function bannerParallax() {
		const top = $( window ).scrollTop();
		const $banner = $( '.banner' );
		const $bannerImg = $( '.banner-img' );
		const posStart = $banner.offset().top;
		const posEnd = posStart + $bannerImg.innerHeight();
		const height = posEnd - posStart;

		if ( top > posStart && top < posEnd ) {
			$bannerImg.css( 'transform', `translate(-50%, ${ top / height * ( height / 2 ) }px)` );
		} else if ( top >= posEnd ) {
			$bannerImg.css( 'transform', `translate(-50%, ${ height / 2 }px)` );
		} else {
			$bannerImg.css( 'transform', `translate(-50%, 0px)` );
		}
	}
	if ( $( '.banner-img' ).length ) {
		bannerParallax();
		$( window ).on( 'scroll', bannerParallax );
	}

	/* - - - - - - - - - - - - - -       subtitle highlight       - - - - - - - - - - - - - - - */
	$( '.js-highlight' ).each( function() {
		const $this = $( this );
		const $paragraph = $this.find( 'p:first-child' );
		const text = $paragraph.text();
		const textCount = text.length;
		const count = 26;
		const startTpl = '<span class="highlight">';
		const endTpl = '</span>';
		if ( count < textCount ) {
			$paragraph.html( startTpl + text.slice(0, count ) + endTpl + text.slice( count ) );
		} else {
			$paragraph.html( startTpl + text + endTpl );
		}
	});

	/* - - - - - - - - - - - - - -       gallery slider       - - - - - - - - - - - - - - - */
	// const gallerySlider = $( '.js-gallery-slider' ).slick({
	// 	infinite: true,
	// 	arrows: false,
	// 	variableWidth: true,
	// 	slidesToShow: 2,
	// 	slidesToScroll: 1,
	// 	swipe: false,
	// 	swipeToSlide: false,
	// 	speed: 10000,
	// 	cssEase: 'linear',
	// 	autoplay: true,
	// 	autoplaySpeed: 0,
	// 	pauseOnHover: false,
	// 	responsive: [
	// 		{
	// 			breakpoint: 1200,
	// 			settings: {
	// 				slidesToShow: 1
	// 			}
	// 		}
	// 	]
	// });
	let gallerySpeed = 0;
	if (window.matchMedia("(min-width: 1200px)").matches) {
		gallerySpeed = 20000;
	} else {
		gallerySpeed = 15000;
	}
	$( '.js-gallery-slider' ).marquee({
		duration: gallerySpeed,
		gap: 0,
		delayBeforeStart: 0,
		direction: 'left',
		duplicated: true,
		startVisible: true
	});

	/* - - - - - - - - - - - - - -       project faq accordion       - - - - - - - - - - - - - - - */

	$('.accordion-block__block:first').addClass('active');
	$('.accordion-block__block:first .text-wrapper').addClass('active');

	$('.accordion-block__block .top-wrapper').click(function () {
		var $accordion = $(this).closest('.accordion-block__block');
		if ($accordion.hasClass('active')) {
			$accordion.removeClass('active').find('.text-wrapper').slideUp(300);
		} else {
			$('.accordion-block__block').removeClass('active').find('.text-wrapper').slideUp(300);
			$accordion.addClass('active').find('.text-wrapper').slideDown(300);
		}
	});
	$('.accordion-wrapper .accordion-view-more').click(function () {
		var $accordionAdditionallyList = $(this).closest('.accordion-wrapper').find('.accordion-additionally-list');
		var $alternativeText = $(this).attr('data-alternative-text');
		if($(this).hasClass('open')){
			$accordionAdditionallyList.slideUp(300);
			$(this).removeClass('open');
		}else{
			$accordionAdditionallyList.slideDown(300);
			$(this).addClass('open');
		}
		if($alternativeText){
			$(this).attr('data-alternative-text', $(this).find('span:first-child').html());
			$(this).find('span:first-child').html($alternativeText);
		}else{
			$(this).hide();
		}


	});
	$('.project-video .video-btn').on("click", function (){
		let video = this.closest('.project-video-wrapper').querySelector('video');
		if(video){
			$(this).closest('.project-video-wrapper').addClass('play-video');
			if(video.hasAttribute('data-src')){
				video.setAttribute('src', video.dataset.src);
			}
			video.play();
		}
	})
	$('.project-video video').on("pause", function() {
		$(this).closest('.project-video-wrapper').removeClass('play-video');
	});
	// apartaments-slider
	let appartamensSliderLenght = $('.apartaments-slider').children().length;
	if(appartamensSliderLenght>1){
		$('.apartaments-slider').slick({
			slidesToShow: 2,
			slidesToScroll: 1,
			autoplay: false,
			autoplaySpeed: 2000,
			appendArrows: '.js-apartaments-slider-arrows',
			prevArrow: "<button type='button' class=\"slick-prev arrow-left2\"><span class=\"icon icon-slider-arrow-left\"></span></button>",
			nextArrow: "<button type='button' class=\"slick-next arrow-right2\"><span class=\"icon icon-slider-arrow-right\"></span></button>",
			responsive: [
				{
					breakpoint: 991,
					settings: {
						adaptiveHeight: true,
						slidesToShow: 1
					}
				},
				{
					breakpoint: 767,
					settings: {
						adaptiveHeight: true,
						slidesToShow: 1,
						centerMode: true,
						centerPadding: "32px"
					}
				}
			]
		});
	}

	// invest-slider
	let investMainSlideItem = $('.project-invest-slider-section .invest-main-slider').children().length;
	$('.project-invest-slider-section .invest-main-slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: investMainSlideItem == 1 ? false : true,
		appendArrows: '.js-invest-main-slider-arrows',
		prevArrow: "<button type='button' class=\"slick-prev arrow-left invest-main-slider-arrow-left\"><span class=\"icon icon-slider-arrow-left\"></span></button>",
		nextArrow: "<button type='button' class=\"slick-next arrow-right invest-main-slider-arrow-right\"><span class=\"icon icon-slider-arrow-right\"></span></button>",
		infinite: investMainSlideItem > 3 ? true : false,
		fade: true,
		asNavFor: '.project-invest-slider-section .invest-text-slider'
	});
	let investTextSlideItem = $('.project-invest-slider-section .invest-text-slider').children().length;
	$('.project-invest-slider-section .invest-text-slider').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: '.project-invest-slider-section .invest-main-slider',
		dots: false,
		arrows: false,
		infinite: investTextSlideItem > 3 ? true : false,
		centerMode: true,
		centerPadding: '180px',
		focusOnSelect: true,
		infinity: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					centerMode: false,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					centerMode: false,
				}
			}
		]
	});

	$('.project-bottom-slider-section .project-bottom-content-slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		appendArrows: '.js-project-bottom-slider-arrows',
		prevArrow: "<button type='button' class=\"slick-prev arrow-left project-bottom-slider-arrow-left\"><span class=\"icon icon-slider-arrow-left\"></span></button>",
		nextArrow: "<button type='button' class=\"slick-next arrow-right project-bottom-slider-arrow-right\"><span class=\"icon icon-slider-arrow-right\"></span></button>",
		fade: true,
		asNavFor: '.project-bottom-slider-section .project-bottom-image-slider'
	});
	$('.project-bottom-slider-section .project-bottom-image-slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: false,
		asNavFor: '.project-bottom-slider-section .project-bottom-content-slider'
	});
	$('.project-bottom-slider-section .project-bottom-content-slider').on('afterChange', function (event, slick, currentSlide) {
		var sliderItemsNum = $(this).find('.slick-slide').not('.slick-cloned').length;
		$(this).closest('.project-bottom-slider-section').find('.project-bottom-slider-nav .slider-number').html(addZeros(currentSlide + 1) + '/' + addZeros(sliderItemsNum));
		console.log()
	});
	function addZeros (n) {
		return (n < 10) ? '0' + n : '' + n;
	}
	$( window ).on( 'scroll', function(){
		const top = $( window ).scrollTop();
		$( '.project-about .image-wrapper' ).each(function () {
			const posStart = $(this).offset().top - $(window).innerHeight();
			const posEnd = $(this).offset().top - 21;
			if ( top > posStart && top < posEnd ) {
				const percent = 100 - (top - posStart) * 100 / (posEnd - posStart);
				if(!$(this).hasClass('animated')){
					$(this).find('.mask').css('height', percent + '%');
					if(percent < 1){
						$(this).addClass('animated');
						$(this).find('.mask').css('height', 0 + '%');
					}
				}

			}
		});
	});
});
